import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "category-table", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: {
                icon: "mdi-office-building",
                inline: "",
                color: "secondary",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "after-heading",
                    fn: function () {
                      return [
                        _c("h1", { staticClass: "h5" }, [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$tc("company_partner", 2))),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1982660779
              ),
            },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    color: "primary",
                    rounded: "",
                    absolute: "",
                    fab: "",
                    top: "",
                    right: "",
                  },
                  on: { click: _vm.handleAddPartnerB2b },
                },
                [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
                1
              ),
              _c(VSimpleTable, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$tc("company_partner", 1))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("users_partners"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$tc("investor", 2))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$tc("investment", 2))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("invested"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("allocations_pending"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("edit"))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.partnersB2b, function (partner, index) {
                    return _c(
                      "tr",
                      {
                        key: index,
                        attrs: { "data-test": "Vehicle:Vehicle:Teste" },
                      },
                      [
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(partner.name)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(partner.totalPartners)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(partner.totalInvestors)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(partner.totalInvestments)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.showCurrencyValue(
                                  partner.totalInvestedBRL,
                                  partner.totalInvestedUSD,
                                  _vm.CurrencyEnum.BRL
                                )
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.showCurrencyValue(
                                  partner.totalInvestedUSD,
                                  partner.totalInvestedBRL,
                                  _vm.CurrencyEnum.USD
                                )
                              )
                            ),
                          ]),
                          !partner.totalInvestedBRL && !partner.totalInvestedUSD
                            ? _c("span", [_vm._v("-")])
                            : _vm._e(),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.showCurrencyValue(
                                  partner.totalPendingBRL,
                                  partner.totalPendingUSD,
                                  _vm.CurrencyEnum.BRL
                                )
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.showCurrencyValue(
                                  partner.totalPendingUSD,
                                  partner.totalPendingBRL,
                                  _vm.CurrencyEnum.USD
                                )
                              )
                            ),
                          ]),
                          !partner.totalPendingBRL && !partner.totalPendingUSD
                            ? _c("span", [_vm._v("-")])
                            : _vm._e(),
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "px-2 ml-1 secondary",
                                attrs: { "min-width": "0", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEditPartnerB2b(partner)
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-pencil"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c(VPagination, {
                attrs: { color: "primary", length: _vm.pageCount },
                on: { input: _vm.changePage },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          ),
          _vm.addOrEditPartnerB2bDialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.addOrEditPartnerB2bDialog,
                    callback: function ($$v) {
                      _vm.addOrEditPartnerB2bDialog = $$v
                    },
                    expression: "addOrEditPartnerB2bDialog",
                  },
                },
                [
                  _c("DialogAddOrEditPartnerB2b", {
                    attrs: { partnerB2bContent: _vm.editPartnerB2bContent },
                    on: {
                      closeDialog: _vm.handleCloseDialog,
                      saved: _vm.handleSavedPartnerB2b,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }